// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@use '@angular/material' as mat;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("../node_modules/angular-calendar/css/angular-calendar.css");
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@ng-select/ng-select/themes/default.theme.css";

body {
  height: 100vh;
  width: 100%;
}

@layer base {
  html {
    font-family: "Inter";
  }

  h1 {
    @apply text-5xl font-extrabold;
  }

  h2 {
    @apply text-3xl font-bold;
  }

  h3 {
    @apply text-2xl font-bold;
  }

  h4 {
    @apply font-bold text-xl;
  }

  .lead {
    @apply text-xl font-normal;
  }

  .large {
    @apply text-lg font-semibold;
  }

  .bold {
    @apply font-black;
  }

  .normal-small {
    @apply text-sm font-normal text-[#182025] dark:text-content1;
  }

  .small {
    @apply text-sm font-medium text-[#71717A] dark:text-content1;
  }

  .extra-small {
    @apply text-[10px] font-medium text-[#71717A] dark:text-content1;
    line-height: 12px;
  }

  p {
    @apply text-base font-normal;
  }

  .sub-title1 {
    @apply text-base font-semibold;
  }

  .sub-title2 {
    @apply text-sm font-normal text-content3;
    letter-spacing: -0.28px;
  }

  .sub-title3 {
    @apply text-base font-medium text-content3;
  }

  .sub-title4 {
    @apply text-base font-normal text-content3;
  }

  .sub-title5 {
    @apply text-base text-[#182025] dark:text-content1;
  }

  .chip-text {
    @apply text-[12px] text-[#A1A1AA] font-[500];
  }

  .link-text {
    @apply text-[16px] font-[600] text-secondary cursor-pointer;
  }

  .stat-text {
    @apply text-[12px] font-medium text-content3 uppercase;
  }

  .table-item {
    @apply text-base font-normal;
  }

  .list {
    @apply text-base font-normal;
  }

  .inline-code {
    @apply text-sm font-extrabold;
  }

  .chip-button {
    @apply text-xs;
  }
}

/* badges */
.badge-pink {
  @apply bg-pink-500 text-white border-pink-500;
}

.badge-flat-pink {
  @apply bg-pink-50 text-pink-500 border-pink-50;
}

.badge-outline-pink {
  @apply border-pink-500 text-pink-500;
}

.badge-primary {
  @apply bg-primary text-white border-primary;
}

.badge-flat-primary {
  @apply bg-[#F4F4F5] text-primary border-[#F4F4F5];
}

.badge-outline-primary {
  @apply border-primary text-primary;
}

.badge-gray {
  @apply bg-[#71717A] text-white border-[#71717A];
}

.badge-flat-gray {
  @apply bg-[#F4F4F5] text-[#71717A] border-[#F4F4F5];
}

.badge-flat-blue{
  @apply bg-[#E8F2FF] text-[#2F469C] border-[#E8F2FF];
}

.badge-outline-gray {
  @apply border-[#71717A] text-[#71717A];
}

.badge-secondary {
  @apply bg-secondary text-white border-secondary;
}

.badge-flat-secondary {
  @apply bg-[#EDE8FB] text-secondary border-[#EDE8FB];
}

.badge-outline-secondary {
  @apply border-secondary text-secondary;
}

.badge-green {
  @apply bg-[#36A800] text-white border-[#36A800];
}

.badge-flat-green {
  @apply bg-[#b1e49a] text-[#36A800];
}

.badge-outline-green {
  @apply border-[#36A800] text-[#36A800];
}
.badge-outline-yellow {
  @apply bg-amber-500 text-amber-50; /* Hex code for a standard yellow */
}


@layer components {
  .card {
    border: 1px solid var(--light-layout-divider, #12121226);
    @apply border-[1px] bg-backgroundSecondary dark:bg-gray-3;
  }
  .active-card {
    border: 1px solid var(--light-layout-divider, #12121226);
    @apply border-[1px] bg-gray-2 dark:bg-gray-7;
  }

  .input {
    border-radius: 6px;
    @apply border-gray-6 bg-gray-1 border-[1px] dark:border-gray-6;
    font-size: 16px;
    font-weight: 400;
  }

  .cancelBtn {
    @apply bg-[#fafafa] dark:bg-gray-1 border-[1px] border-[#12121226] dark:border-gray-6 text-[black] dark:text-[white];
  }

  .uploadbtn {
    @apply bg-[#fafafa] border-[1px] border-[#12121226] text-[black] rounded-[6px];
  }

  .cancelBtn:hover {
    @apply border-error bg-primary border-primary;
  }

  .editBtn {
    @apply bg-[#fafafa] dark:bg-gray-1 border-[1px] border-[#12121226] dark:border-gray-6;
  }

  .form-label {
    @apply text-[#11181c] dark:text-content1;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  .select {
    border-radius: 6px;
    @apply border-[1px];
  }

  .popover-content,
  .popover-arrow {
    @apply bg-white dark:bg-gray-3;
  }

  .dropdown-menu {
    @apply bg-white dark:bg-gray-3 dark:border-[1px] dark:border-gray-6;
  }

  /* Table Global Stayl
  e */

  .tablecontainer {
    /* border: 1px solid var(--light-layout-divider, #12121226); */
    @apply bg-white border-[1px] dark:bg-gray-3 dark:border-gray-6;
  }

  .btn-ghost-primary {
    --tw-text-opacity: 1;
    background-color: transparent;
    border-style: none;
    @apply text-primary;
    transition-duration: 0.15s;
    transition-property: transform, color, background-color, border-color,
    text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .btn-ghost-primary:hover {
    background: #fdd0df;
  }

  .btn-ghost-secondary {
    --tw-text-opacity: 1;
    background-color: transparent;
    border-style: none;
    @apply text-secondary;
    transition-duration: 0.15s;
    transition-property: transform, color, background-color, border-color,
    text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .btn-ghost-secondary:hover {
    background: #d0c8f6;
  }

  .btn-ghost-warning {
    --tw-text-opacity: 1;
    background-color: transparent;
    border-style: none;
    @apply text-warning;
    transition-duration: 0.15s;
    transition-property: transform, color, background-color, border-color,
    text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  .btn-ghost-warning:hover {
    background: #fdedd3;
  }

  /* .chat-box{
    @apply max-h-[calc(80%-200px)]
} */
}

/* ---------------- ANGULAR CALANDER CSS --------------------*/
.cal-month-view {
  @apply dark:bg-[#121212];
}

.cal-week-view {
  @apply dark:bg-[#121212];
}

.cal-hour.cal-hour-odd.ng-star-inserted {
  @apply dark:bg-[#121212];
}

.cal-day-number {
  @apply dark:text-content1;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  @apply dark:text-content1;
}

//.cal-cell-row {
//  @apply dark:hover:bg-[#121212];
//}

.cal-month-view .cal-day-cell.cal-today {
  @apply dark:bg-transparent bg-transparent border-primary border-2;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  @apply text-primary
}

.cal-week-view .cal-header.cal-today {
  @apply dark:bg-secondary;
}

.cal-week-view .cal-header.cal-weekend span {
  @apply dark:text-content1;
}

.cal-month-view .cal-cell-row:hover {
  @apply dark:bg-transparent;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  @apply dark:bg-content3;
}

.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  @apply dark:bg-content3;
}

.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  @apply dark:bg-content3;
}

.cal-week-view .cal-event-container:not(.cal-draggable){
  width: 100% !important;
  left: 0 !important;
}

.cal-week-view .cal-hour-odd {
  @apply bg-transparent;
}
.cal-week-view .cal-event {
  @apply dark:text-content1 text-white text-lg;
}

.cal-week-view .cal-event, .cal-week-view .cal-header{
  @apply whitespace-normal
}
/* .cal-week-view .cal-event-container:not(.cal-draggable) {
  @apply cursor-default;
} */

.cal-week-view .cal-day-column {
  @apply border-[#e1e1e1] border-[1px] dark:border-gray-6;

}
.cal-week-view .cal-day-headers {
  @apply border-[#e1e1e1] border-[1px] dark:border-gray-6;
}

.cal-week-view .cal-day-headers .cal-header:first-child{
  @apply border-[#e1e1e1] border-[1px] dark:border-gray-6;
}

.cal-week-view .cal-day-headers .cal-header:not(:last-child){
  @apply border-[#e1e1e1] border-[1px] dark:border-gray-6;
}

.cal-week-view .cal-time-events{
  @apply border-[#e1e1e1] border-[1px] dark:border-gray-6;
}

.cal-week-view{
  @apply border-[#e1e1e1] border-[1px] dark:border-gray-6;
}



.error {
  /* @apply text-[#F31260]; */
  color: #f31260;
}

.checkbox {
  border-radius: 5px;
}

.form-field {
  margin-bottom: 16px;
}

.card {
  border-radius: 12px;
  max-width: 100%;
  margin: 32px 0;
}

.card-body {
  padding: 24px;
}

.card-footer {
  padding: 24px;
}

.card-head-tabs {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}

.editBtn {
  border-radius: 6px;
  border: 1px solid;
  /* color: #4f45e3; */
  font-weight: 600;
  font-size: 14px;
  padding: 8px 16px;
  @apply hover:bg-secondary text-secondary border-secondary;
}

.primaryBtn {
  border-radius: 6px;
  /* border: 1px solid primary; */
  /* color: #4f45e3; */
  font-weight: 600;
  font-size: 14px;
  padding: 8px 16px;
  @apply hover:bg-primary border-primary border-[1px] text-primary;
}

.cancelBtn {
  border-radius: 6px;
  /* color: black; */
  font-weight: 600;
  font-size: 14px;
  padding: 8px 16px;
}

.saveBtn {
  border-radius: 6px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 16px;
}

.card-footer {
  justify-content: flex-end;
}

i {
  font-size: 24px;
}

.icon-small-left {
  font-size: 16px;
  margin-right: 4px;
}

.icon-small-right {
  font-size: 16px;
  margin-left: 4px;
}

.icon-extra-large {
  color: #a1a1aa;
  font-size: 30px;
  cursor: pointer;
}

.icon-large {
  color: #a1a1aa;
  font-size: 24px;
  cursor: pointer;
}

.info {
  color: #a1a1aa;
  font-size: 20px;
  cursor: pointer;
}

.edit-table-icon {
  color: #4f45e3;
  font-size: 20px;
  cursor: pointer;
}

.delete-table-icon {
  color: #f31260;
  font-size: 20px;
  margin-left: 0px;
  cursor: pointer;
}

.breadcrumbs {
  margin-bottom: 16px;
  padding: 0;
  margin-top: 4px;
}

.between {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.divider {
  /* p-0 m-0 mt-[-10px] bg-red-700 h-0 */
  padding: 0px;
  margin: 0px;
  margin-top: -10px;
  height: 0px;
}

select option {
  border-radius: 4px;
  border: 0;
  outline: none;
}

.radio-group {
  /* flex cursor-pointer gap-2 items-center */
  display: flex;
  cursor: pointer;
  gap: 15px;
  align-items: center;
  padding-bottom: 10px;
}

/* Global  Style For Table */

.tablecontainer {
  position: relative;
  width: 100%;
  padding: 12px;
  margin-top: 32px;
  border-radius: 12px;
}

.container:before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background-size: 22px 32px;
}

table {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
  border-radius: 50px;
}

thead tr {
  text-align: left;
  height: 20px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  @apply text-content2 bg-[#f7f9fc] dark:bg-gray-4;
}

thead tr th {
  @apply px-[14px] py-[6px] uppercase whitespace-nowrap;
}

tbody tr:hover {
  @apply bg-[#f7f9fc] dark:bg-gray-4;
}

.head-text {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

thead tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

thead tr th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

tbody td {
  @apply border-gray-6 border-b-[1px] text-content2 px-[14px] py-[6px];
}

tbody td:first-child {
  transition: all ease 0.3s;
}

tbody tr:last-child td {
  border-bottom: 0;
}

/* tbody tr:hover td:first-child {
  border-left-color: #0F6FDE;
} */

td.status {
  text-align: center;
  justify-content: center;
}

td.status span {
  font-size: 12px;
  border-radius: 3px;
  padding: 0px;
}

td.status span.approved {
  color: #ffffff;
  background-color: #00c455;
}

td.status span.decline {
  color: #ffffff;
  background-color: #f13426;
}

td.status span.pending {
  color: #132d4a;
  background-color: #ebf0f5;
}

@media screen and (max-width: 900px) {
  .container {
    position: relative;
    width: 100%;
    padding: 0px;
    margin-top: 32px;
    border: 0px solid #e7e7e7;
    border-radius: 12px;
  }

  table tbody tr {
    position: relative;
    width: calc(50% - 8px);
    padding: 8px;
    margin: 4px;
    @apply border-gray-6 border-b-[1px];
  }
  tbody>tr>td{
    border: 0;
    @apply flex items-center justify-center
  }

  .action-col-table{
    bottom: 160px;
  }

}

@media screen and (max-width: 1400px) {
  .table-grid-view tbody tr {
    width: calc(48% - 5px) !important
  }
}

@media screen and (max-width: 900px) {
  table thead {
    display: none;
  }

  table tbody {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
    border: 0;
  }

  table tbody tr{
    display: block;
    border: 0;
  }

  table tbody td {
    padding: 4px;
    font-weight: bold;
  }

  table tbody td:before {
    //content: attr(data-name);
    //width: 100px;
    justify-content: center;
    display: flex;
    text-transform: capitalize;
    font-weight: normal;
  }

  table tbody td.status {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  table tbody td.status i {
    text-align: center;
    align-items: center;
  }

  .edit-table-icon {
    border: 1px solid #4f45e3;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 4px;
  }

  .delete-table-icon {
    border: 1px solid #f31260;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 4px;
  }

  table tbody tr {
    position: relative;
    width: calc(100% - 8px);
    border: 1px solid;
    @apply border-gray-6;
    padding: 8px;
    margin: 4px;
    border-radius: 12px;
  }
}



.modal-body {
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.modal-footer {
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  @apply flex justify-end gap-3;
}

.modal-footer-center {
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  @apply flex gap-3;
}

.modal-header {
  @apply flex flex-row justify-between items-center min-w-[350px] pl-[1.5rem] pt-[1rem];
}

.modal-header-desc {
  @apply sub-title2 px-[1.5rem] max-w-[350px]
}

.modal-body {
  margin-top: 0;
  padding-top: 0;
}

.btn-download {
  @apply btn btn-sm btn-ghost text-content3 items-center dark:hover:bg-backgroundPrimary p-2;
}

.btn-delete {
  @apply btn btn-sm btn-ghost p-2 text-[#F31260] hover:bg-red-4;
}

.btn-edit {
  @apply btn btn-sm btn-ghost text-secondary items-center hover:bg-blue-4 p-2;
}

.avatar {
  @apply bg-none;
}

.ng-select.custom {
  @apply min-w-[50px] bg-none;
}

.ng-select .ng-select-container {
  @apply text-content1 rounded-md min-w-[50px] bg-transparent border-[1px] border-[#12121226] dark:border-gray-6;
}

.ng-select.ng-select-opened>.ng-select-container {
  @apply bg-transparent;
}

.ng-select.custom.abc.ng-select-single .ng-select-container {
  height: 2.5rem;
}

.ng-dropdown-panel {
  animation: growDown 0.25s ease-in-out forwards;
  transform-origin: top center;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07),
  0px 15px 35px 0px rgba(50, 50, 93, 0.1);
  @apply bg-white dark:bg-gray-3 dark:border-[1px] border-[#12121226] dark:border-gray-6 shadow-md;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  @apply bg-white dark:bg-gray-3 text-content2;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  @apply bg-gray-4 dark:text-primary mx-1 rounded-sm text-content1 font-normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  @apply bg-primary text-white m-1 rounded-md font-normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-primary text-white m-1 rounded-md font-normal;
}

.ng-dropdown-panel.ng-select-bottom {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  @apply dark:border-[1px] border-t-[#12121226] dark:border-t-gray-6;
}

.ng-select.ng-select-opened>.ng-select-container {
  @apply dark:border-[1px] border-[#12121226] dark:border-gray-6;
}

.ng-select.ng-select-focused:not(.ng-select.ng-select-opened)>.ng-select-container {
  @apply dark:border-[1px] border-[#12121226] dark:border-gray-6 shadow-none;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

/* for toggle Table */

.table-grid-view thead {
  display: none;
}

.table-grid-view tbody {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  border: 0;
  gap: 17px;
}

.table-grid-view tbody tr,
.table-grid-view tbody td {
  display: block;
  border: 0;
}

.table-grid-view tbody td {
  padding: 4px;
  font-weight: bold;
}

.table-grid-view tbody td:before {
  content: attr(data-name);
  width: 100px;
  display: flex;
  text-transform: capitalize;
  font-weight: normal;
}

.table-grid-view tbody td.status {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.table-grid-view tbody td.status i {
  text-align: center;
  align-items: center;
}

.edit-table-icon {
  border: 1px solid #4f45e3;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 4px;
}

.delete-table-icon {
  border: 1px solid #f31260;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 4px;
}

/* Imp Start */

.table-grid-view tbody tr {
  position: relative;
  border: 1px solid;
  @apply w-[calc(33%-8px)] max-md:min-w-[calc(50%-8px)] max-sm:min-w-[calc(100%-8px)] border-gray-6 gap-20;
  padding: 8px;
  margin: 0px;
  border-radius: 12px;
}

.table-grid-view tbody tr {
  @apply grid grid-cols-2 gap-4;
}

/* Imp End */

/* Normal Table */
.table-view-tbody {
  @apply max-md:grid max-sm:grid-cols-1 max-md:grid-cols-2;
}

.table-view-tr {
  @apply max-md:grid max-sm:grid-cols-2 max-md:grid-cols-2;
}

.grid-view-tbody {
  @apply grid grid-cols-2;
}

.grid-view-tr {
  @apply grid grid-cols-2;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {
  scrollbar-width: none;
  overflow: hidden;
  -ms-overflow-style: none;
}

/* .hide-scroll-bar::-webkit-scrollbar {
  display: none;

}

.hide-scroll-bar {
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
} */

/* .cal-hour-segment.cal-hour-start{
  @apply flex justify-center items-center;
} */

.answer-text {
  @apply text-secondary;
}

.modal {
  z-index: 1000;
}

.modal-content {
  max-width: 100%;
}

.cal-disabled {
  //pointer-events: none;
  //@apply dark:bg-[#121212] bg-gray-100;
}

.cal-disabled .cal-day-number {
  //opacity: 0.5;
}

/* slider */
/*
  ::ng-deep .ngx-slider-span .ngx-slider-bar-wrapper .ngx-slider-selection-bar {
    display: block;
  }

  ::ng-deep .ngx-slider-span .ngx-slider-bar {
  background-color: rgba(var(--primary-color), 0.5);
} */

.badge-cross {
  @apply rounded-full text-primary ml-2 bg-white w-[17px] h-[17px] text-[14px] flex flex-row items-center justify-center cursor-pointer;
}

/* calendar Available legend */
.available-legend {
  @apply bg-[#BEDBFF];
}

.request-legend {
  @apply bg-[#2F469C];
}

.ng-select.ng-select-disabled>.ng-select-container {
  @apply bg-transparent;
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
  @apply bg-transparent text-neutral border-none;
}

.ngx-slider .ngx-slider-tick-legend {
  font-size: smaller;
  font-weight: 600;
  @apply text-[#71717A] dark:text-content1;
}


/* Normal Icon With Background  */

.screeners-icon,
.interviews-icon,
.incentives-icon,
.manage-icon,
.study-icon,
.info-icon {
  width: 44px;
  height: 44px;
  background-color: #ede8fb;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2f469c;
}

.screeners-icon {
  background-color: #ede8fb;
  color: #2f469c;
}

.interviews-icon {
  background-color: #fdedd3;
  color: #f5a524;
}

.incentives-icon {
  background-color: #e8faf0;
  color: #17c964;
}


/* Small Icon With background */

.success-icon-small,
.secondary-icon-small,
.primary-icon-small,
.warning-icon-small,
.gray-icon-small,
.error-icon-small {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-icon-small {
  background-color: #E8FAF0;
  color: #17C964;
}

.secondary-icon-small {
  background-color: #EDE8FB;
  color: #2F469C;
}

.primary-icon-small {
  background-color: #DFF0F2;
  color: #009D9C;
}

.warning-icon-small {
  background-color: #FEFCE8;
  color: #F5A524;
}

.gray-icon-small {
  background-color: #F4F4F5;
  color: #71717A;
}

.error-icon-small {
  background-color: #FEE7EF;
  color: #F31260;
}

.dotted-border {
  border: 2px dotted #12121226;
  border-radius: 8px;
  padding: 4px 10px;
  font-weight: 900;
  font-size: 16px;
  @apply dark:border-gray-10
}

.payPal-icon {
  @apply text-[#0070E0];
}

.payPal-button {
  @apply bg-[#0070E0] text-white;
}

.stripe-icon {
  @apply text-[#635BFF];
}

.stripe-button {
  @apply bg-[#635BFF] text-white;
}

.ngx-slider .ngx-slider-pointer{
  z-index: 0;
}



// our modifications:

.question-option {
  @apply flex cursor-pointer gap-2 items-center;
}

.action-col-table{
  //position: absolute;
  //right: 0;
  //margin-top: 1px;
}

.grid-view-tr {
  height: auto !important;
  margin-bottom: 10px;
  @apply grid grid-cols-2
}


.grid-view-tr .action-col-table{
  bottom: 160px;
  left:250px;
}

.error-field{
  @apply text-red-500 text-xs;
  position: absolute;
  bottom: -10px;
}

//:root {
//  --neutral-08: rgb(244, 244, 244);
//  --neutral-07: rgb(252, 252, 252);
//  --neutral-06: rgb(252, 252, 252);
//
//  --secondary-01: rgb(255, 188, 153);
//  --secondary-02: rgb(202, 189, 255);
//  --secondary-03: rgb(177, 229, 252);
//  --secondary-04: rgb(181, 228, 202);
//  --secondary-05: rgb(255, 216, 141);
//  --secondary-06: rgb(216, 216, 216);
//
//  //--main-backgound-color: #F4F4F4;
//  --main-backgound-color: rgb(255, 255, 255);
//  --main-backgound-secondary-color: rgb(226, 230, 234);
//  --primary-background-color: rgba(57, 67, 78, 0.04);
//  --primary-background-color-opaque: rgba(249, 249, 249, 1);
//  --secondary-background-color-opaque: rgb(241, 241, 241);
//  --referral-background-color: rgb(225, 234, 252);
//  --primary-text-color: rgb(35, 42, 47);
//  --secondary-text-color: transparent linear-gradient(180deg, rgba(237, 238, 240, 1) 0%, rgba(217, 220, 224, 1) 100%);
//  --secondary-background-color: rgb(250, 250, 250);
//  --button-background-color: rgba(255, 255, 255, 1);
//  --button-text-color: rgb(89, 100, 105);
//  --text-field-disabled-input-text-color: rgba(0,0,0,0.38);
//  --border-color: rgb(224, 224, 224);
//  --primary-icon-color: rgba(57, 67, 78, 1);
//  --primary-hightlight-color: rgba(248, 248, 248, 1);
//  --danger-background-color: rgba(248, 248, 248, 1);
//  --danger-text-color: rgb(255, 0, 0);
//  --soft-danger-text-color: rgb(255, 104, 104);
//  --bluish-background-color: rgba(48, 159, 209, 1);
//  --list-item-background-color: rgba(237, 244, 255, 1);
//  --semi-success-text-color: rgb(199, 255, 79);
//  --success-text-color: rgba(0, 191, 165, 1);
//  --success-background-color: rgba(237, 244, 255, 1);
//  --dialog-background-color: rgba(255, 255, 255, 1);
//  --disabled-background-color: rgba(143, 143, 143, 1);
//  --highlight-background-secondary-color: rgba(255, 214, 133, 1);
//  --highlight-background-color: rgba(255, 255, 129, 1);
//  --action-color: rgba(176, 121, 252, 1);
//  --secondary-action-color: rgba(0, 174, 255, 1);
//  --strong-action-color: rgba(0, 0, 255, 1);
//  --strong-oragnge-color: rgb(255, 133, 35, 1);
//  --outgoing-chat-message-color: rgba(202, 240, 237, 100);
//  --ingoing-chat-message-color: rgba(251, 235, 173, 100);
//  --linkedin: rgb(0, 119, 181);
//  --disabled-background-color-free-value: 143, 143, 143;
//  --action-color-free-value: 176, 121, 252;
//  --shadow-color-free-value: 0, 0, 0;
//  --secondary-action-color-free-value: 0, 174, 255;
//  --orangeish-color-free-value: 255, 167, 94;
//  --greenish-color-free-value: 199, 255, 79;
//  --reddish-color-free-value: 255, 104, 104;
//  --svg-contranst-value: 1;
//  --hour-label-text-color: rgb(114, 118, 142);
//  --day-label-text-color: rgb(32, 33, 36);
//  --cell-border-color: rgb(226, 226, 226);
//  --event-background-color: rgba(51, 153, 255, 0.5);
//  --event-border-color: rgb(204, 204, 204);
//  --event-text-color: rgb(51, 51, 51);
//  --current-time-line-color: rgb(255, 0, 0);
//  --hour-line-border-color: rgb(226, 226, 226);
//  --today-button-text-color: rgb(60, 64, 67);
//  --today-button-border-color: rgb(226, 226, 226);
//  --current-day-cell-color: rgb(240, 240, 240);
//  --cal-event-actions-link-color: rgb(0, 172, 193);
//  --temporary-event-background-color: rgb(255, 0, 0);
//  --date-range-color: rgb(107, 122, 153);
//  --button-border-color: rgb(245, 246, 247);
//  --button-hover-background-color: rgb(248, 249, 250);
//  --icon-border-color: rgb(195, 202, 217);
//  --secondary-background-color-free-value: 241, 241, 241;
//}
//
//
//:root.DARK {
//  --neutral-08: rgb(17, 19, 21);
//  --neutral-07: rgb(26, 29, 31);
//  --neutral-06: rgb(39, 43, 48);
//
//  --secondary-01: rgb(255, 188, 153);
//  --secondary-02: rgb(202, 189, 255);
//  --secondary-03: rgb(177, 229, 252);
//  --secondary-04: rgb(181, 228, 202);
//  --secondary-05: rgb(255, 216, 141);
//  --secondary-06: rgb(216, 216, 216);



//  //DEPRECATED COLORS - PLEASE USE ABOVE
//  //--main-backgound-color: rgb(30, 30, 30);
//  --main-backgound-color: #111315;
//  --main-backgound-secondary-color: rgb(61, 61, 63);
//  //--primary-background-color: rgba(34, 34, 34, 1);
//  --primary-background-color: #1A1D1F;
//  --primary-background-color-opaque: rgba(34, 34, 34, 1);
//  --referral-background-color: rgb(46, 46, 47);
//  --primary-text-color: rgb(227, 227, 227);
//  --secondary-text-color: rgba(153, 153, 153, 1);
//  --text-field-disabled-input-text-color: rgba(255,255,255,0.38);
//  --secondary-background-color-opaque: rgb(66, 66, 66);
//  --secondary-background-color: rgba(45, 45, 45, 1);
//  --button-background-color: rgb(80, 65, 171);
//  --button-text-color: rgba(255, 255, 255, 0.81);
//  --border-color: rgba(137, 137, 137, 1);
//  --primary-icon-color: rgb(189, 189, 189);
//  --primary-hightlight-color: rgba(86, 86, 109, 1);
//  --danger-background-color: rgba(89, 52, 52, 1);
//  --danger-text-color: rgb(255, 146, 146);
//  --soft-danger-text-color: rgb(255, 104, 104);
//  --bluish-background-color: rgba(48, 159, 209, 1);
//  --list-item-background-color: rgba(77, 77, 77, 1);
//  --semi-success-text-color: rgb(128, 169, 47);
//  --success-text-color: rgba(66, 187, 66, 1);
//  --success-background-color: rgba(83, 128, 83, 1);
//  --dialog-background-color: rgba(45, 45, 45, 1);
//  --disabled-background-color: rgba(77, 77, 77, 1);
//  --highlight-background-secondary-color: rgb(215, 169, 90);
//  --highlight-background-color: rgba(119, 119, 58, 1);
//  --action-color: rgb(101, 90, 164);
//  --secondary-action-color: rgba(0, 80, 119, 1);
//  --strong-action-color: rgba(42, 74, 89, 1);
//  --strong-oragnge-color: rgba(255, 133, 35, 1);
//  --linkedin: rgb(0, 119, 181);
//  --outgoing-chat-message-color: rgba(0, 86, 78, 100);
//  --ingoing-chat-message-color: rgba(34, 34, 34, 1);
//  --disabled-background-color-free-value: 143, 143, 143;
//  --action-color-free-value: 80, 65, 171;
//  --svg-contranst-value: 0;
//  --secondary-action-color-free-value: 74, 193, 255;
//  --orangeish-color-free-value: 255, 167, 94;
//  --greenish-color-free-value: 199, 255, 79;
//  --shadow-color-free-value: 211, 211, 211;
//  --reddish-color-free-value: 255, 104, 104;
//  --secondary-background-color-free-value: 45, 45, 45;
//  --day-label-text-color: rgb(114, 118, 142);
//  --today-button-text-color: rgb(114, 118, 142);
//}


@import 'assets/css/scrollbars.scss';
@import 'assets/css/fonts.scss';
@import 'assets/css/buttons.scss';
@import 'assets/css/variables.scss';
@import 'assets/css/components.scss';
@import 'assets/css/texts.scss';
@import 'assets/css/angular-override.scss';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
